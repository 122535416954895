import React from "react";

import BoxAccordion from "../components/box-accordion";

const Accordion = ({ items }) => {
  return (
    <div className="box-accordion-wrap">
      {items.map((item, i) => (
        <div key={i}>
          <BoxAccordion data={item} />
        </div>
      ))}
    </div>
  );
};

export default Accordion;
