import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Accordion from "../components/accordion";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Spline = lazy(() => import("@splinetool/react-spline"));

const Faq = ({ data }) => {
  const { faq } = data;

  return (
    <Layout>
      <Seo title={`Domande & FAQ`} description={`Domande & FAQ`} />

      <div className="box-intro box-intro--page-name">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/ge4aZVlIQVLjlThm/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="box-intro__page-name">
                  <span>Domande &</span> faq
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="faq" className="section section--grey-dark">
        <Accordion items={faq.nodes?.[0].accordionItems} />
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  query {
    faq: allContentfulAccordionPage(filter: { entryTitle: { eq: "Faq" } }) {
      nodes {
        accordionItems {
          title
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
