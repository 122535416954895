import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BoxAccordion = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`box-accordion${isOpen ? " box-accordion--open" : ""}`}>
      <div className="box-accordion__title">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="box-accordion__btn text-start d-flex justify-content-between align-items-center"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span>
                  {data.title} <strong>{data.year}</strong>
                </span>
                <span className="box-accordion__arrow"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box-accordion__text">
        <div>
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-1 col-xl-7 offset-xl-1">
                <div
                  dangerouslySetInnerHTML={{ __html: data.text.childMarkdownRemark?.html }}
                ></div>
              </div>
            </div>
            {data.winners && data.winners.length && (
              <div className="row">
                <div className="col-12 col-lg-11 offset-lg-1">
                  <div className="d-flex flex-wrap">
                    {data.winners.map((child, j) => (
                      <div key={j} className="box-winner text-center">
                        <div className="box-winner__logo d-flex justify-content-center align-items-center">
                          <GatsbyImage
                            image={getImage(child.image)}
                            alt={child.title}
                            className=""
                            loading="eager"
                          />
                        </div>
                        {child.claim && <div className="box-winner__claim">{child.claim}</div>}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxAccordion;
